import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-chronoblog/src/components/page/index.js";
import { Header, Summary, Experience, Projects, Skills, List, Education, Footer, OpenSourceContrib, Accomplishments } from '../components';
import resume from '../../data/profile';
import '../main.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Button = makeShortcode("Button");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Header contacts={resume.contact} name={resume.fullname} role={resume.role} mdxType="Header" />
    <Summary data={resume.summary} mdxType="Summary" />
    <div className="border-b border-neutral-300 pb-2 my-5 lg:flex">
    <div className="lg:w-2/3 lg:pr-8">
      {resume.experience && <Experience data={resume.experience} mdxType="Experience" />}
      {resume.accomplishments && <Accomplishments data={resume.accomplishments} mdxType="Accomplishments" />}
      {resume.open_source_contribs && <OpenSourceContrib data={resume.open_source_contribs} mdxType="OpenSourceContrib" />}
    </div>
    <div className="lg:w-1/3 lg:pl-8 lg:border-l lg:border-neutral-300 ">
      {resume.education && <Education data={resume.education} mdxType="Education" />}
      {resume.skills && <Skills data={resume.skills} mdxType="Skills" />}
      {resume.sidebar && resume.sidebar.map(item => <List key={`${item.title}-side`} data={item} mdxType="List" />)}
    </div>
    </div>
    <h3>Blog Posts&nbsp;&nbsp;
  <Link to='blog' mdxType="Link">
    <Button style={{
          marginTop: '8px'
        }} mdxType="Button">View all</Button>
  </Link>
    </h3>
    <p>{`Coming Soon`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      